body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "AvenirBlack", "AvenirMedium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"), url(theme/fonts/AvenirLT-Black.woff) format("woff");
}

@font-face {
  font-family: "AvenirMedium";
  src: local("AvenirMedium"),
    url(theme/fonts/AvenirLT-Medium.woff) format("woff");
}
